import Log1 from './logo.png';
import Mac from './mac.svg';
import Android from './android.svg';
import Web from './web.png';
import Iphone from './iphone.png';
import X from './x.png';
import Arb from './arb.png';
import Telegram from './telegram.png';
import './App.css';
import play from './playnow.svg';

import Ido from './Ido.jsx';

import Token from './token.png'
import Log01 from './logo01.svg';

import Log3 from './log3.svg';
import Log4 from './log4.svg';
import Log5 from './log5.svg';
import Log6 from './log6.svg';
import Log7 from './log7.svg';
import Log8 from './log8.svg';
import Logg from './logo00.svg'
import '@rainbow-me/rainbowkit/styles.css';
import MP4 from './mp4bg.mp4'

import NFT1 from './nft1.jpg'
import NFT2 from './nft2.jpg'
import NFT3 from './nft3.jpg'
import NFT4 from './nft4.jpg'
import NFT5 from './nft5.jpg'
import NFT6 from './nft6.jpg'


//import BigNumber from 'bignumber';
import {  darkTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  bsc
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';



const { chains, publicClient } = configureChains(
  [bsc],
  [
    alchemyProvider({ apiKey: 'LdWpmHgbPUKE83HEFQ18drZV1SYe9moz' }),
    publicProvider()
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'aaaa',
  projectId: '9a680ad66ce38240a059ced361958b86',
  chains
});


const config  = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})
const { ethers } = require("ethers");










function App() {
  return (
    <div className="App">


      <div className='main'>
      <video  id="bgvideo" autoplay="" loop="loop" poster="" src={MP4} muted="muted">

      </video>
      <div className="title"> </div>
                  <header className="App_header">
        <div className='header_logo'>
          <img className='header_logo_img' src={Log1} alt=""/>
        </div>

            <div className='header_main'>
             <div className='whitepaper'>
              <a className='game_b' href="https://gamefi-snake.gitbook.io/snakebattle/" target="_blank">whitepaper</a>

            </div>
              <div className='buysnake'>
              <a className='game_c' href="https://pancakeswap.finance/swap?chain=bsc&inputCurrency=0x3A79979d0AF4C6DfA5AdF1138560D2b2493EC378&outputCurrency=BNB" target="_blank">BUY $SNAKE</a>


            </div>
          </div>
            <div className='fllow'>


              <div className="Footer_socialButton__1mnuI">
                <a href="https://t.me/+gSOQCI2SxKM4ZmZl" target="_blank" rel="noreferrer">
                  <img src={Telegram} alt="tele" className="social"/>
                </a>
                <a href="https://twitter.com/snake1coin" target="_blank" rel="noreferrer">
                  <img src={X} alt="twitter" className="social"/>
                </a>
                <a href="https://bscscan.com/address/0x3a79979d0af4c6dfa5adf1138560d2b2493ec378" target="_blank" rel="noreferrer">
                  <img src={Arb} alt="uniswap" className="social"/>
                </a>
              </div>
            </div>
      </header>

      </div>



      <div className='nftbg'>
        <div><h2 className="tokenomics_">NFT</h2></div>
        <div><p className="tokenomics_detail">There will be no duplicute of NFT in the plafram</p></div>
        <div className='nft'>

              <img src={NFT3} alt=""/>

              <img src={NFT2} alt=""/>

              <img src={NFT1} alt=""/>


        </div>

        <div className='nft'>

              <img src={NFT6} alt=""/>

              <img src={NFT4} alt=""/>

              <img src={NFT5} alt=""/>


        </div>


      </div>







      <div className='moblie_a'>
      <div  className="ant-row" >
        <div className="ant-col_ ">
          <img src={Iphone} alt="iphone" className="iphone"/>
        </div>

        <div className="ant-col">
          <h2 className="SectionFour_title__3XLJD">Mobile Friendly Gameplay for all Devices</h2>
            <p className="SectionFour_info__L0sBY">The SnakeGame web app will be available on both desktop and mobile devices, optimized for all screen sizes and compatible with the leading crypto wallets like TrustWallet, Metamask, Coinbase Wallet and more.</p>
          <div className="SectionFour_iconGroup__3ynhp">
            <img src={Mac} alt="mac" className="SectionFour_icon__3dCDC"/>
            <img src={Android} alt="android" className="SectionFour_icon__3dCDC"/>
            <img src={Web} alt="web" className="SectionFour_icon__3dCDC"/>
          </div>
        </div>

      </div>
      </div>





      <div className="tokenbg"> 
      <div className='tokenomics'>
        <h2 className="tokenomics_">Token Metric</h2>
        <p className="tokenomics_detail">TOTAL SUPPLY: 100,000,000,000</p>
      </div>

      

      
      <div className="ant_row" >
      <div className="token_img">

        <img className="ant_image_img" src={Token}/>

      </div>

          <div className="SectionThree_statsTitle__2ttjt">

          <p>SNAKE Airdrop: 5%</p>
          <p>Initial Liquidity: 45%</p>
          <p>SNAKE IDO: 50%</p>

          </div>


      </div>
        <div className='swiper'>

        <div className='group'>
          <div className='groupa'>
            <img className='log1' src={Log01} alt="logo"/>

            <img className='log1'src={Log3} alt="logo"/>
            <img className='log1'src={Log4} alt="logo"/>
            <img className='log1'src={Log5} alt="logo"/>
            <img className='log1'src={Log6} alt="logo"/>
            <img className='log1'src={Log7} alt="logo"/>
            <img className='log1'src={Log8} alt="logo"/>
            <img className='log1' src={Logg} alt="logo"/>

            <img className='log1'src={Log3} alt="logo"/>
            <img className='log1'src={Log4} alt="logo"/>
            <img className='log1'src={Log5} alt="logo"/>
            <img className='log1'src={Log6} alt="logo"/>
            <img className='log1'src={Log7} alt="logo"/>
            <img className='log1'src={Log8} alt="logo"/>
            <img className='log1'src={Log3} alt="logo"/>
            <img className='log1'src={Log4} alt="logo"/>
          </div>
          <div className='groupa_hidden'></div>
        </div>
      </div>
      </div>



      
      <div className='ido'>

      <WagmiConfig config={config}>

        <Ido/>

       </WagmiConfig>   

      </div>
     









      <div className='Roadmap_' id='Roadmap'>
        <div >
        <h2 className='tokenomics_'>Roadmap</h2></div>
        <div className='tokenomics_detail'>
          <p className='.tokenomics_detail'>SNAKE Game keeps growing. Check out our infinite potential together!.
          </p>
        </div>
        <div className='roadmap_Phase'>
          <div className='Phase'>
            <div className='Phase_div'>Phase   1</div>
            <div className='Phase_ul'>

                <p>Project concept</p>
                <p>Game style</p>
                <p>Building website</p>
                <p>$SNAKE token contract</p>
                <p>Game ecosystem structure</p>
            </div>
          </div>
          <div className='Phase'>
            <div className='Phase_div'>Phase   2</div>
            <div className='Phase_ul'>

                <p>Game teaser</p>
                <p>Game contract</p>
                <p>Website staging</p>
                <p>Markeplace Launch</p>               
                <p>NFT design</p>
                <p>PVP environment</p>

            </div>
          </div>
          <div className='Phase'>
            <div className='Phase_div'>Phase   3</div>
            <div className='Phase_ul'>

                <p>Launch APP ANDROID </p>
                <p>Launch APP IOS</p>
                <p>Launch Of Sponsored Competitions</p>
                <p>Seeking collaboration</p>
                <p>Multiverse</p>


            </div>
          </div>
        </div>
      </div>

      <footer className="Footer_footerContainer__2MTT5">
        <div className="Footer_footerWrapper__pZQFt">

        <div type="flex" className="ant_middle" >
          <div className="antcol">
              <img className="footimg" src={Log1} alt="logo" />
          </div>
          

          <div className="footer_right">

          </div>

        </div>
          <div className="antdivider" role="separator"></div>
        </div>
      </footer>

    </div>
  );
}

export default App;
