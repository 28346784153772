import React ,{ useState } from 'react';
import './ido.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  bsc
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useContractWrite, usePrepareContractWrite } from 'wagmi'
import { useAccount } from 'wagmi'
import ABI from './abi.json';

import {  darkTheme } from '@rainbow-me/rainbowkit';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import BigNumber from 'bignumber.js';


const { chains, publicClient } = configureChains(
  [bsc],
  [
     jsonRpcProvider({
      rpc: () => ({
        http: `https://bsc-mainnet.nodereal.io/v1/a084cca058124809a4aa14d84128b507`,
      }),
    }),
    publicProvider()
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'aaaa',
  projectId: '9a680ad66ce38240a059ced361958b86',
  chains
});


const config  = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})
const { ethers } = require("ethers");


export default function Ido() {
	const [Snake, setSnake] = useState(0);
	const [state, setState] = useState('');
	const { address } = useAccount();
	const [statebig, setStatebig] = useState()
	
	function Webindex(){


			const htmlHref = window.location.href;
			htmlHref = htmlHref.replace(/^http:\/\/[^/]+/, "");
			const addr = htmlHref.substr(htmlHref.lastIndexOf('/', htmlHref.lastIndexOf('/') - 1) + 1);
			const index = addr.lastIndexOf("\/");
			//js 获取字符串中最后一个斜杠后面的内容
			const addrLast = decodeURI(addr.substring(index + 1, addr.length));
			//js 获取字符串中最后一个斜杠前面的内容
			const str = decodeURI(addr.substring(0, index));
			console.log(addrLast+ "  " +str);

	}


	const {write } = useContractWrite({
    address: '0xBC06c0FD889fa2cEFAE9e4795e745e0F03321C2A',
    abi: ABI,
    functionName: 'preSale',
    value: BigNumber(statebig),
    args: ['0x598F12f81b13701fa0aA235eC5a045F21BDaC6D0'],

    onError(error) {

       console.log(error)

    },
   })



	return (
		<div className='ido'>
			<div className='publicsale_main '>
				<h2 className='publicsale '>Sale</h2>


			</div >
			<div className='contract'>
				<p>contract:0x3A79979d0AF4C6DfA5AdF1138560D2b2493EC378</p>
			</div>
			<div className='price'>
				<p>
					Add all pre-sale BNB to the liquidity pool 
				</p>
				<p>
					price:1BNB=3,000,000,000$SNAK
				</p>
			</div>

			<div className='maininput'> 
				<div className='text'>
					<input className='text_input' type="text" value={state} onChange={(evt)=>{
						setState(evt.target.value)
						setStatebig(evt.target.value *1000000000000000000)
						setSnake(evt.target.value*3000000000)
					}}/>
					<div className='text_bnb'>BNB</div>
				</div>


				<div className='buya' onClick={
                () =>{ 
              if (address !==undefined){
                write(state)

              }else{
                console.log(address)
              }
              } }>buy</div>
			</div>
			<div className='invited'>
				<p className='receivesnake'>You can receive {Snake} $SNAKE  </p>
			</div>
			{/**  theme={darkTheme({
      			accentColor: '#BC8334',
      			accentColorForeground: '#0e1321',
      			borderRadius: 'small',
      			fontStack: 'system',
     			 overlayBlur: 'small',
    			})}*/}
    		<div className='button'>
			    <RainbowKitProvider chains={chains} theme={darkTheme({
            accentColor: '#BC8334',
            accentColorForeground: '#0e1321',
            borderRadius: 'none',
            fontStack: 'rounded',
            overlayBlur: 'none',
          })}>
              <ConnectButton accountStatus="address" chainStatus="none"/>
          </RainbowKitProvider>
       </div>



		</div>
	)
}